import CmsIcon from '@components/Cms/CmsComponents/CmsIcon/CmsIcon'
import { useExternalsActions } from '@hooks/useExternalsAction'
import { ITeaserCallToAction } from '@typesApp/cmsPlacement/LXTeaser'
import CmsCtaModal from '../CmsCtaModal'
import { camelCase, getHref } from './helpers'
import { StyledCmsIcon } from './CmsCta.style'
import { Button } from '@components/UI/Button'
import { ButtonProps } from '@typesApp/button'
import { StyledAnchorButton } from '../AnchorButtons/AnchorButtonsStyle'
import { useRouter } from 'next/router'
import { locationOriginSelector } from '@redux/selectors/site'
import { useSelector } from 'react-redux'

type CmsCta = {
  teaserCtaSetting: ITeaserCallToAction
  overrideStyle?: ITeaserCallToAction['style']
  currentPath?: string
  teaserIcon?: string
  elementId?: string
}

// TODO type correctly the style
// TODO handle Modal
const getCtaStyle = (style: ITeaserCallToAction['style']) => {
  // TODO improve this split type
  const getValues = style.split('-')
  const variant = (getValues[2] || 'primary') as ButtonProps['variant']
  const fillType = (getValues[1] || 'fill') as ButtonProps['fillType']

  return {
    variant,
    fillType,
  }
}

export const CmsCta: React.FC<CmsCta> = ({ teaserCtaSetting, teaserIcon, currentPath, overrideStyle, elementId }) => {
  const { locale, isLocaleDomain } = useRouter()
  const locationOrigin = useSelector(locationOriginSelector)

  if (!teaserCtaSetting?.callToActionEnabled) null

  const ctaStyle = getCtaStyle(overrideStyle ?? teaserCtaSetting.style)
  const { callToActionText, callToActionHash, target } = teaserCtaSetting ?? {}
  const { type, url: externalUrl, formattedUrl } = target ?? {}
  const isExternal = ['CMExternalLink'].includes(type)
  const url = isExternal ? externalUrl : formattedUrl
  const hasURL = Boolean(url)
  const actions = useExternalsActions()
  const hasAction = target?.type === 'Action'
  const name = target.name?.split(' ')
  const dataName = camelCase(name?.[name.length - 1] || '')
    .replace('contactLensesCategory', '')
    .replace('contactLensesType', '')
    .replace(/[{()}]/g, '')

  if (hasAction) {
    const action = actions[target.idAction]

    return (
      <Button {...ctaStyle} onClick={action}>
        {callToActionText}
      </Button>
    )
  }

  const linkProps: ReturnType<typeof getCtaStyle> & {
    href: string
    onClick?: (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void
  } = {
    href: getHref(currentPath, url, callToActionHash),
    onClick: undefined,
    ...ctaStyle,
  }

  if (!hasURL) {
    linkProps.onClick = event => {
      event.preventDefault()
      event.stopPropagation()
      document?.getElementById(`${callToActionHash}`)?.scrollIntoView({ behavior: 'smooth' })
    }
  }

  if (teaserCtaSetting.target?.type === 'LXTeaser') {
    return <CmsCtaModal text={callToActionText} ctaStyle={ctaStyle} teaser={target} />
  }

  return (
    <StyledAnchorButton
      href={linkProps.href}
      data-name={dataName}
      data-element-id={elementId || ''}
      locale={locale as string}
      isLocaleDomain={isLocaleDomain}
      locationOrigin={locationOrigin}
    >
      {teaserIcon && (
        <StyledCmsIcon>
          <CmsIcon teaserIcon={teaserIcon} />
        </StyledCmsIcon>
      )}
      {callToActionText}
    </StyledAnchorButton>
  )
}
