import { LinkAsButton } from '@components/UI/Button/LinkAsButton'
import { styled, linkClasses as MuiLinkClasses } from '@mui/material'

export const StyledButtonContainer = styled('div', {
  name: 'AnchorButtonContainer',
})(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gap: theme.spacing(8),
  margin: theme.spacing(-10, 0),

  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(4, 1fr)',
    gap: theme.spacing(12),
  },

  [`& .${MuiLinkClasses.root}`]: {
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

export const StyledAnchorButton = styled(LinkAsButton, {
  name: 'AnchorButton',
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(4),
  color: theme.palette.common.white,
  backgroundColor: theme.palette.secondary.main,
  width: '100%',

  '&:hover': {
    backgroundColor: theme.palette.primary.tones[50],
  },
}))

export const StyledCmsIcon = styled('div', {
  name: 'AnchorButtonIcon',
})(() => ({
  height: 32,
  width: 32,

  '& svg': {
    height: 'inherit',
    width: 'inherit',
  },
}))

export const StyledList = styled('ul', {
  name: 'AnchorButtonList',
})(() => ({
  listStyle: 'none',
  padding: 0,
  margin: 0,
}))

export const StyledListItem = styled('li', {
  name: 'AnchorButtonListItem',
})(() => ({
  padding: 0,
  margin: 0,
}))
